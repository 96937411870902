<template>
  <Modal :value="visible"
         @on-visible-change="onVisibleChange"
         footer-hide
         title="分享"
         width="680">
    <div class="share"
         v-if="obj">
      <div class="share-title">
        <FileIcon :file="data[0]"
                  class="m-r-10"/>
        <span>{{data[0].name}}</span>
        <span v-if="data.length>1"> 等{{data.length}}项内容</span>
      </div>
      <div class="share-con">
        <div class="share-url">
          <Input :value="shareUrl"
                 @on-search="copyShareUrl"
                 disabled
                 enter-button="复制链接"
                 search
                 style="width:388px;margin-bottom:15px"/>
          <span @click.stop="getShareCode"
                style="color:#2D8CF0"
                v-if="!shareCode">生成分享码</span>
          <div class="flex a-center"
               v-else>
            <Button>{{shareCode}}</Button>
            <span @click.stop="cancleShareCode"
                  style="color:#999999">取消分享码</span>
          </div>
        </div>
        <div class="share-ewm">
          <img src="../../assets/common/share_img_qrcode.png">
          <p>微信扫一扫二维码，<br>将文件分享给好友</p>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import {editShareUrlInfo, getShareUrlInfo} from '../../services/diskapi'
import FileIcon from '../../components/common/FileIcon'

export default {
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: {
    visible: Boolean,
    data: Array,
    obj: Object
  },
  components: {FileIcon},
  data () {
    return {
      shareCode: ''
    }
  },
  computed: {
    shareUrl () {
      if (this.obj) {
        let host = window.location.host
        return host + '/share/' + this.obj.shareKey
      } else {
        return ''
      }
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      getShareUrlInfo('KHbPXXAJ').then(res => {
      })
    },
    // 点击取消
    onClickCancle () {
      this.$emit('change', false)
    },
    // 显示状态改变
    onVisibleChange (boo) {
      if (!boo) this.onClickCancle()
    },
    // 生成分享码
    getShareCode () {
      function random (max, min) {
        return Math.round(Math.random() * (max - min) + min)
      }

      //创建一个空字符，用于存放随机数/字母
      var strData = ''
      //生成随机字符库 (验证码四位，随机数三种，取公倍数12,所以循环4次。也可以是120次，1200次。)
      for (var i = 0; i < 4; i++) {
        var num = random(0, 9)//生成0-9的随机数
        var az = String.fromCharCode(random(97, 122))//生成a-z
        var AZ = String.fromCharCode(random(65, 90))//生成A-Z
        strData = strData + num + az + AZ//将生成的字符进行字符串拼接
      }
      // 开始真正的随机(从随机字符库中随机取出四个)
      var str = ''
      for (var i = 0; i < 4; i++) {
        str += strData[random(0, strData.length - 1)]
      }
      this.editShareUrlInfo(str)
    },
    // 取消分享码
    cancleShareCode () {
      this.editShareUrlInfo('')
    },
    // 修改分享链接信息
    editShareUrlInfo (code) {
      let params = {
        sharePwd: code,
        shareId: this.obj.shareId
      }
      editShareUrlInfo(params).then(res => {
        if (res.code === 0) {
          this.shareCode = code
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    },
    // 复制分享链接
    copyShareUrl () {

    }
  },
}
</script>

<style lang='scss' scoped>
  .share {
    padding: 4px 14px;

    .share-title {
      display: flex;
      align-items: center;
    }

    .share-con {
      display: flex;

      .share-url {
        flex: 1;
        padding: 40px 0;
        border-right: 1px solid #ededed;
      }

      .share-ewm {
        padding-left: 50px;
        padding-right: 10px;

        img {
          width: 108px;
          height: 108px;
        }

        p {
          font-size: 14px;
          color: #666;
          line-height: 20px;
        }
      }
    }
  }
</style>
