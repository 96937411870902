<template>
  <div class='breadcrumb'>
    <ul>
      <li class="href"
          @click="returnBefore"
          v-if="data.length>1">
        <p>返回上一级</p>
        <span style="position:relative;top:-2px">|</span>
      </li>
      <template v-for="(item,index) in data">
        <li :key="item.id"
            :class="{href:(index!==(data.length-1))}"
            @click="onClickItem(item)">
          <p class="line1"
             :title="item.name">{{item.name}}</p>
          <span v-if="index!==(data.length-1)">></span>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    data: Array
  },
  data () {
    return {

    }
  },
  methods: {
    returnBefore () {
      let item = this.data[this.data.length - 2]
      this.onClickItem(item)
    },
    onClickItem (item) {
      this.$emit('onClickItem', item)
    }
  }
}

</script>
<style lang='scss' scoped>
.breadcrumb {
  ul {
    display: flex;
    align-items: center;
    li {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 1;
      p {
        max-width: 180px;
      }
      &.href {
        color: #2d8cf0;
        p {
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
        span {
          padding: 0 10px;
        }
      }
    }
  }
}
</style>